import React from "react";
import ReactDOM from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";

// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// import "./assets/css/index.css";
// import "./i18n";
// import "./firebase";
// import App from "./App";
// import Loader, { LoaderSuspense } from "./utils/loader";
// // import reportWebVitals from "./reportWebVitals";
// import DevHighLight from "./components/DevHighLight";
import "./index.css";
import Blank from "./components/Blank"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* <DevHighLight />
    <React.Suspense fallback={<LoaderSuspense />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <Loader />
      <ToastContainer />
    </React.Suspense> */}
    <Blank />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
