import React from "react";

import logo from "../assets/logo-dark.png";

const Blank = () => {
  return (
    <div className="bg-dark h-screen flex flex-col justify-center">
      <img src={logo} className="w-48 mx-auto" alt="MasterCard" />
    </div>
  );
};

export default Blank;
